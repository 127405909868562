import { useBreakpoint } from "@/hooks/useBreakpoints"
import { EmbedHologram } from "@/lib/hologramProps"
import { useGesture } from "@use-gesture/react"
import { getCDNRect } from "components/HologramThumbnail"
import { motion, useMotionValue } from "framer-motion"
import { useStore } from "hooks/useStore"
import { getSourceCDNUrl } from "lib/cdn"
import { useEffect, useRef } from "react"
import { config, useSpring, animated } from "react-spring"
import { lerp, mapLinear, smootherstep } from "three/src/math/MathUtils"

interface HologramBackgroundProps {
	hologram: EmbedHologram
	fullscreen?: boolean
	//**blur in pixels, defaults to 16px */
	blur?: number
	size?: number
}

export default function HologramBackground(props: HologramBackgroundProps) {
	const { hologram, fullscreen, blur = 3, size = 2000 } = props
	const aspectRatio = hologram.aspectRatio ?? 1
	const hologramAngle = useStore((store) => store.curHologramAngle)
	const imageUrl =
		hologram.type === "QUILT"
			? getCDNRect({ hologram, blur, width: size, height: size / aspectRatio })
			: getSourceCDNUrl(hologram, { blur, width: size, height: size / aspectRatio })

	const x = useMotionValue(0)
	const ref = useRef<HTMLDivElement>(null)

	const isDesktop = useBreakpoint("md")

	useEffect(() => {
		x.set(mapLinear(hologramAngle, 0, 1, -30, 30))
	}, [hologramAngle])

	useGesture(
		{
			onMove: ({ event }) => {
				if (!ref.current) return
				const { clientX } = event
				if ((event.target as HTMLElement).id === "selling-points") {
					// backgroundPositionX.start(0, { config: config.gentle })
					return
				}
				const rect = ref.current.getBoundingClientRect()
				const normalizedX = smootherstep(clientX, rect.left, rect.right)
				const offset = lerp(-50, 50, normalizedX)
				// backgroundPositionX.start(offset)
			},
		},
		{ target: ref },
	)

	return (
		<div
			id="background"
			style={{
				position: fullscreen ? "fixed" : "absolute",
				// width: "calc(100% - 11px)",
				width: "100%",
				top: 0,
				left: 0,
				overflow: "hidden",
				display: "flex",
				justifyContent: "center",
				zIndex: -1,
				height: fullscreen ? "100%" : "100dvh",
			}}>
			<div
				style={{
					position: "absolute",
					inset: 0,
					backgroundImage:
						"radial-gradient(ellipse at center, transparent 0%, transparent 90%, #000000 120%)",
					zIndex: 1,
					pointerEvents: "none",
				}}
			/>
			<motion.img
				src={imageUrl}
				style={{ translateX: x, scale: isDesktop ? 1.4 : 1.3 }}
				className={`h-full flex-grow object-cover object-center brightness-95 sm:h-auto`}
			/>
		</div>
	)
}
