import { EmbedHologram } from "@/lib/hologramProps"
import UploadButton from "components/UploadButtonHomePage"
import { useRef } from "react"
import { animated } from "react-spring"
import { getCDNRect } from "../HologramThumbnail"
import HoloText from "../HoloText"
import HologramBackground from "../Navigation/HologramBackground"
import BlockCanvas from "../renderer/BlockCanvas"
import { DesignMark } from "./DesignMark"
import HeroSellingPoints from "./HeroSellingPoints"

interface BannerProps {
	hologram: EmbedHologram
}

export default function Banner({ hologram }: BannerProps) {
	const url = getCDNRect({ hologram, blur: 6, width: 750, height: 750 / (hologram.aspectRatio ?? 0.75) })
	const ref = useRef<HTMLDivElement>(null)

	return (
		<animated.div ref={ref}>
			<HologramBackground hologram={hologram} blur={16} />
			{/* <img className="absolute mt-[-80px] h-[90dvh] object-cover" src={url} /> */}
			{/* Use this image tag to preload the background to prevent flickering, since bg images are lazy loaded */}
			<img style={{ display: "none" }} src={url} />
			<div className="flex h-[calc(100vh-80px-80px)] flex-col items-center justify-start gap-2 overflow-y-visible px-2 py-8 text-center md:h-[calc(100vh-80px)]">
				<HoloText className="pb-2 text-4xl font-black sm:text-5xl md:text-6xl">
					Goodbye GIFs.
					<br />
					Meet holograms.
				</HoloText>
				<div className="text-shadow mt-4 text-lg text-white md:text-2xl">
					The first holographic sharing platform <br className="hidden md:block" />
					built for 3D creators.
				</div>
				<UploadButton id="create-a-hologram-button-hero" />
				<BlockCanvas
					interactionContainer={ref}
					hologram={hologram}
					className="z-[1] h-auto w-full max-w-screen-md"
				/>
			</div>
			<div className="pointer-events-none absolute inset-0 z-[1] hidden pt-[76px] md:block">
				<DesignMark
					src="/home/rounded_x.svg"
					className={`absolute left-[10%] top-[10%] origin-center -translate-x-1/2 -translate-y-1/2 md:left-[20%] md:top-[30%]`}
					width={161}
					height={171}
				/>
				<DesignMark
					src="/home/pyramid.svg"
					className={`absolute left-[80%] top-[14%] origin-center -translate-x-1/2 -translate-y-1/2 md:top-[25%]`}
					width={196}
					height={236}
					delay={0.5}
				/>
				<DesignMark
					src="/home/squiggly.svg"
					className={`absolute left-[82%] top-[45%] origin-center -translate-x-1/2 -translate-y-1/2 md:left-[76%] md:top-[65%]`}
					width={310}
					height={300}
					rotate={false}
				/>
			</div>
			<HeroSellingPoints />
		</animated.div>
	)
}
