import useOnboardingStore from "hooks/useOnboardingStore"
import { AppState, useStore } from "hooks/useStore"
import { useBlocksUserStore } from "hooks/useStore"
import { useRef } from "react"
import SpinnerLoader from "./SpinnerLoader"

export interface UploadButtonProps {
	id: string
}

export default function UploadButton(props: UploadButtonProps) {
	const user = useBlocksUserStore((state) => state.dbUser)
	const isLoggedIn = useBlocksUserStore((state) => state.current?.isLoggedIn)

	const { isHologramUploading } = useStore((state: AppState) => state)
	const { setCurrentStep, getResumeStep } = useOnboardingStore()

	const buttonRef = useRef<HTMLButtonElement | null>(null)

	async function onUploadClick() {
		if (!isLoggedIn) {
			// Must hard redirect to any auth page
			window.location.href = "/api/auth/login?returnTo=/discover"
		} else if (user) {
			const resumeStep = await getResumeStep(user)
			if (resumeStep) {
				setCurrentStep(resumeStep)
			} else {
				setCurrentStep("select_upload_type")
			}
		}
	}

	return (
		<>
			<div>
				<button
					id={props.id}
					ref={buttonRef}
					className="bg-holo5 lkg-black whitespace-nowrap rounded-md px-20 py-3 font-bold drop-shadow-md active:drop-shadow-md"
					onClick={onUploadClick}>
					{isHologramUploading ? (
						<SpinnerLoader className="h-full" />
					) : (
						<p className="lkg-black">Create a Hologram</p>
					)}
				</button>
			</div>
		</>
	)
}
