import { Linky } from "components/Linky"
import Image from "next/image"
import { ReactNode } from "react"

export default function HeroSellingPoints() {
	return (
		<div
			id="selling-points"
			className="relative mt-[-20dvh] bg-white px-8 pb-32 pt-8 md:mt-[-20dvh] md:px-0 md:pt-44">
			<div className="mx-auto space-y-16 pt-[64px] md:grid md:max-w-7xl md:grid-cols-3 md:gap-16 md:space-y-0 md:px-8 md:pt-[0px]">
				<HomeBannerDetailText
					header="Convert anything."
					icon={{ name: "icon-render", width: 62, height: 60 }}>
					<p className="text-black">
						Turn dreams into reality with the ability to convert any 2D image into a hologram with our
						built-in AI conversion tool.{" "}
						<Linky
							href="https://lookingglassfactory.com/blog/introducing-image-to-hologram-on-blocks"
							className="text-indigo-600 transition-colors hover:text-fuchsia-600"
							target="_blank">
							Learn more.
						</Linky>
					</p>
					<Logos logos={["unity", "unreal", "blender"]} />
				</HomeBannerDetailText>
				<HomeBannerDetailText
					header={<>View anywhere.</>}
					icon={{ name: "icon-view", width: 71, height: 60 }}>
					<p className="text-black">
						Share and embed your holograms across the internet on any device and cast them directly onto your{" "}
						<Linky
							href="https://lookingglassfactory.com"
							className="text-indigo-600 transition-colors hover:text-fuchsia-600"
							target="_blank">
							Looking Glass displays
						</Linky>
						.
					</p>
					<Logos logos={["desktop", "mobile", "arvr", "lookingglass"]} />
				</HomeBannerDetailText>
				<HomeBannerDetailText header="Embed freely." icon={{ name: "icon-embed", width: 88, height: 60 }}>
					<p className="text-black">
						Present your 3D scenes in the way you designed them. No adjustments to lighting or texture needed.
						What you see is what you get.
					</p>
					<Logos logos={["notion", "slack", "discord"]} />
				</HomeBannerDetailText>
			</div>
		</div>
	)
}

interface HomeBannerDetailTextProps {
	header: ReactNode
	children: ReactNode
	icon: {
		name: string
		width: number
		height: number
	}
}

function HomeBannerDetailText({ header, children, icon }: HomeBannerDetailTextProps) {
	return (
		<div className="space-y-4">
			<Image src={`/home/${icon.name}.svg`} alt="" width={icon.width} height={icon.height} />
			<div className="font-rubik text-4xl font-black text-black">{header}</div>
			<div className="space-y-4 text-base">{children}</div>
		</div>
	)
}

interface LogoProps {
	logos: string[]
	size?: number
	gap?: number
}

export function Logos({ logos, size, gap }: LogoProps) {
	//set default for size prop
	if (!size) {
		size = 8
	}
	if (!gap) {
		gap = 0
	}
	return (
		<div className={`flex flex-row place-items-center space-x-4 pt-4 gap-${gap}`}>
			{logos.map((name) => (
				<div key={name} className={``}>
					<img className={`h-${size}`} src={`/home/logos/${name}.svg`} />
				</div>
			))}
		</div>
	)
}
